import Sidebar from "../../components/Sidebar";
import { Container, Row, Col, Card, Table, Button, Form, InputGroup } from 'react-bootstrap';
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import setting from '../../setting.json';
import Topbar from "../../components/Topbar";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CategoryTree from '../../components/CategoryTree';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const schema = yup
    .object().shape({
        status: yup.string().required('status the required'),
        role: yup.string().required('role the required'),
        name: yup.string().required('Name the required'),
        user_location: yup.string().required('user_location the required'),
        email: yup.string().required('email the required'),
        phone: yup.string().required('phone the required'),
        password: yup.string(),
        permission: yup.string().required('meta_title the required'),


    })

const Users = () => {
    const [list, setList] = useState(null);
    const [id, setId] = useState('');
    const [add, setAdd] = useState(true);
    const [pass, setPass] = useState('');


    const {
        register,
        reset,
        formState: { errors },
        handleSubmit
    } = useForm({
        resolver: yupResolver(schema),
    });



    const getUsers = async () => {
        var url = setting.api;
        await fetch(url + '/api/getUsersList')
            .then(response => response.json())
            .then(json => {
                setList(json);
            }
            );
    }
    const addUser = async (data) => {

        if (add) {
            let ad = new FormData();
            ad.append('status', data.status);
            ad.append('role', data.role);
            ad.append('fname', data.name);
            ad.append('user_location', data.user_location);
            ad.append('email', data.email);
            ad.append('phone', data.phone);
            ad.append('password', data.password);
            ad.append('permission', data.permission);


            var url = setting.api;
            await fetch(url + '/api/saveUser',
                {
                    body: ad,
                    method: "post",
                    mode: "cors", // no-cors, *cors, same-origin
                    headers: {
                        'Accept': 'application/FormData',
                    }
                })
                .then(response => response)
                .then(data => {
                    setAdd(true);
                    getUsers();
                    reset({
                        "status": '',
                        "name": '',
                        "role": '',
                        "user_location": '',
                        "email": '',
                        "phone": '',
                        "password": '',
                        "permission": '',


                    })

                });
        } else {
            let ad = new FormData();
            ad.append('id', id);
            ad.append('status', data.status);
            ad.append('role', data.role);
            ad.append('fname', data.name);
            ad.append('user_location', data.user_location);
            ad.append('email', data.email);
            ad.append('phone', data.phone);
            if (data.password !== '') {
                ad.append('password', data.password);
            }
            ad.append('permission', data.permission);

            var url = setting.api;
            await fetch(url + '/api/updateUser',
                {
                    body: ad,
                    method: "post",
                    headers: {
                        'Accept': 'application/FormData',
                    }
                })
                .then(response => response)
                .then(data => {
                    setAdd(true);
                    getUsers();
                    reset({
                        "status": ' ',
                        "name": ' ',
                        "role": ' ',
                        "user_location": ' ',
                        "email": ' ',
                        "phone": ' ',
                        "permission": ' '

                    })
                });
        }

    }

    const deleteUser = async (id) => {
        var ans = window.confirm("Do you want to continue? Y/N");
        if (ans == true) {
            var url = setting.api;

            await fetch(url + '/api/deleteUser?id=' + id)
                .then(response => response)
                .then(json => {
                    getUsers();
                }
                );
        }

    }

    useEffect(() => {
        getUsers();

    }, []);

    const getUserById = (id) => {
        setAdd(false);
        setId(id);
        var rec;
        list.filter((a) => {
            if (a.id == id) {
                rec = a;
                reset({

                    "status": rec.status,
                    "name": rec.fname,
                    "role": rec.role,
                    "user_location": rec.user_location,
                    "email": rec.email,
                    "phone": rec.phone,
                    "password": pass,
                    "permission": rec.permission

                })

            }
        })

    }

    return (

        <Container fluid >
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>
                <Sidebar />
                {JSON.parse(localStorage.getItem('user')).role === 'admin' &&
                    <Col md={10} lg={10} className="rightsect">
                        <h2 className="pagetitle">Users-{add ? "true" : "false"}</h2>
                        <Row>
                            <Col md={5} lg={5}>
                                <Form onSubmit={handleSubmit(addUser)}>
                                    <Card>
                                        <Card.Header className="head">
                                            Add User
                                        </Card.Header>
                                        <Card.Body>

                                            <Row>
                                                <Form.Group as={Col} className="mb-3" >
                                                    <Form.Label>Status</Form.Label>
                                                    <Form.Select aria-label="Default select example" {...register("status")}>
                                                        <option value="1">Active</option>
                                                        <option value="0">Inactive</option>
                                                    </Form.Select>
                                                    {errors.status && <p>{errors.status.message}</p>}
                                                </Form.Group>
                                                <Form.Group as={Col} className="mb-3" >
                                                    <Form.Label>Role</Form.Label>
                                                    <Form.Select aria-label="Default select example" {...register("role")}>

                                                        <option value="editor">Editor</option>
                                                        <option value="correspondent">Correspondent</option>

                                                    </Form.Select>
                                                    {errors.role && <p>{errors.role.message}</p>}
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group as={Col} className="mb-3" controlId="name">
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Name"  {...register("name")} />
                                                    {errors.name && <p>{errors.name.message}</p>}
                                                </Form.Group>

                                                <Form.Group as={Col} className="mb-3" controlId="location">
                                                    <Form.Label>User Location</Form.Label>
                                                    <Form.Control type="text" placeholder="User Location"  {...register("user_location")} />
                                                    {errors.user_location && <p>{errors.user_location.message}</p>}
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group as={Col} className="mb-3" >
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="email"  {...register("email")} />
                                                    {errors.email && <p>{errors.email.message}</p>}
                                                </Form.Group>
                                                <Form.Group as={Col} className="mb-3" >
                                                    <Form.Label>Phone</Form.Label>
                                                    <Form.Control type="phone" placeholder="Phone" {...register("phone")} />
                                                    {errors.phone && <p>{errors.phone.message}</p>}
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group as={Col} className="mb-3" >
                                                    <Form.Label>Password</Form.Label>
                                                    <Form.Control type="password" placeholder="Password" {...register("password")} />

                                                </Form.Group>
                                                <Form.Group as={Col} className="mb-3" >
                                                    <Form.Label>Post Permission</Form.Label>
                                                    <Form.Select aria-label="Default select example" {...register("permission")}>
                                                        <option value="1">Draft</option>
                                                        <option value="2">Publish</option>
                                                    </Form.Select>
                                                    {errors.permission && <p>{errors.permission.message}</p>}
                                                </Form.Group>
                                            </Row>

                                            {/* <Row>
                                                <Form.Group as={Col} className="mb-3" >
                                                    <Form.Label>Image</Form.Label>
                                                    <Form.Control type="file" />
                                                </Form.Group>



                                            </Row> */}
                                            {/*  <Row>
                                            <ul className="tree">
                                                {
                                                    categories && categories.map((r) =>
                                                        <li key={r.id}>
                                                            <Form.Check
                                                                type='checkbox'
                                                                value={r.id}
                                                                label={r.name}

                                                            />
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </Row> */}
                                            <Button type='submit' variant='success'  >Submit</Button>
                                        </Card.Body>
                                    </Card>
                                </Form>
                            </Col>
                            <Col md={7} lg={7}>
                                <Card>
                                    <Card.Header className="head">
                                        Users
                                    </Card.Header>
                                    <Card.Body>

                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>Name/Role</th>
                                                    <th>Email</th>
                                                    <th>Action</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list && list.map((r, index) =>
                                                    <tr key={r.id}>
                                                        <td>{index + 1}</td>
                                                        <td>{r.fname}<br /><b>{r.role}</b></td>
                                                        <td>
                                                            Url: {setting.appurl}<br />
                                                            Email: {r.email}<br />
                                                            Password: {r.password2}
                                                        </td>
                                                        <td>

                                                            <Button variant='link' size='sm' onClick={() => getUserById(r.id)} ><EditIcon /></Button>


                                                            {' '}

                                                            <Button variant="danger" size='sm' onClick={() => deleteUser(r.id)}><DeleteIcon /></Button>
                                                        </td>
                                                    </tr>
                                                )
                                                }
                                            </tbody>
                                        </Table>

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                    </Col>
                }
                {
                    JSON.parse(localStorage.getItem('user')).role !== 'admin' &&
                    <Col md={10} lg={10} className="rightsect">
                        <h2 className="pagetitle">Not Allowed</h2>
                    </Col>
                }
            </Row >


        </Container >

    );
}

export default Users;